@font-face {
    font-family: 'mirage';
    src: url('./assets/fonts/made_mirage/MADE Mirage Regular PERSONAL USE.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'mirage_black';
    src: url('./assets/fonts/made_mirage/MADE Mirage Black PERSONAL USE.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'mirage_bold';
    src: url('./assets/fonts/made_mirage/MADE Mirage Bold PERSONAL USE.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'mirage_medium';
    src: url('./assets/fonts/made_mirage/MADE Mirage Medium PERSONAL USE.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'mirage_thin';
    src: url('./assets/fonts/made_mirage/MADE Mirage Thin PERSONAL USE.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'restora_light';
    src: url('./assets/fonts/nasir-udin-restora/Nasir Udin - RestoraExtraLight.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'restora_italic';
    src: url('./assets/fonts/nasir-udin-restora/Nasir Udin - RestoraThinItalic.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}