html, body, #root {
    min-height: 100%;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}
/*media query for mobile devices*/
@media (max-width: 768px) {
    html, body, #root {
        scroll-snap-type: none;
        scroll-behavior: smooth;
    }
    .page {
        scroll-snap-align: none;
    }
}

#root {
}
.page {
    scroll-snap-align: start;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 3s ease-in; /* Cambia 2s por la duración que prefieras */
}